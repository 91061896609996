import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import {uuid} from 'uuidv4';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import api from '../../services/api';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErrors';
// import apiIbge from '../../services/apiIbge';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Header,
  ButtonOrders,
  Text,
  AddressField,
  StreetInputField,
  InputField,
  UserAddress,
  ButtonGroup,
  ChangeAddress,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  CancelButton,
  DesableCepButton
  // ValidAddressTitle,
} from './styles';

const SearchAddressByDistance = () => {
  const {
    restaurantId,
    setUserAddressId,
    setUserAddress,
    userAddress,
    setRestaurantDeliveryTax,
    restaurantAddress,
    userLogout
  } = useAuth();
  const history = useHistory();
  const formRef = useRef(null)

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalErrorApi, setModalErrorApi] = useState(false);
  const [modalErrorApiToken, setModalErrorApiToken] = useState(false);

  const[modalPhrase, setModalPhrase] = useState('');

  Modal.setAppElement('#root');

  const [, setCount] = useState();
  const [selectedState, setSelectedState] = useState(restaurantAddress);
  const [selectedStreet, setSelectedStreet] = useState('');
  const [disableCep, setDisableCep] = useState(false);


  const maskOnlyNumbers = value => {
    return value.replace(/\D/g, '');
  };

  // const getStatesData = useCallback(async () => {
  //   try {
  //     const response = await apiIbge.get('/localidades/estados');
  //     const parsedStates = response.data.map((item) => {
  //       return {
  //         label: item.nome,
  //         value: item.sigla,
  //       };
  //     });
  //     setStates(parsedStates);
  //   } catch (err) {
  //     alert('Erro ao carregar informações');
  //   }
  // }, []);

  // const getCitiesData = useCallback(async () => {
  //   try {
  //     console.log(selectedState);
  //     const response = await apiIbge.get(
  //       `/localidades/estados/${selectedState.value}/municipios`
  //     );

  //     const parsedCities = response.data.map((item) => {
  //       return {
  //         label: item.nome,
  //         value: item.nome,
  //       };
  //     });
  //     setCities(parsedCities);
  //   } catch (err) {
  //     alert('Erro ao carregar informações');
  //   }
  // }, [selectedState]);

   function handleNavigate() {
    history.push('/deliverytype');
  }

  function handleChangeAddress() {
    setUserAddress('');

    const aleatoryId = uuid()
    setCount(aleatoryId)
   }

  function closeModal() {
    setIsOpen(false);
  }

  function closeErrorModal() {
    setModalErrorIsOpen(false);
  }

  function closeModalErrorApi() {
    setModalErrorApi(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openErrorModal() {
    setModalErrorIsOpen(true);
  }

  function openModalErrorApi() {
    setModalErrorApi(true);
  }

  function closeModalWithChange() {
    setUserAddress('');

    const aleatoryId = uuid()
    setCount(aleatoryId)

    setIsOpen(false);

  }

  function handleDisableCep(){
    setDisableCep(!disableCep)
  }

  function toggleModalErrorApiToken(){
    setModalErrorApiToken(!modalErrorApiToken)
  }

  function handleNavigateLogin(){
    toggleModalErrorApiToken();
    history.push('/cart')
  }

  async function handleValidateAddress() {
    try {
      const res = await api.post(
        'client/restaurant-delivery-address/validate',
        {
          restaurant_id: restaurantId,
          delivery_address: {
            city: userAddress.city || '',
            country: userAddress.country || '',
            state: userAddress.state || '',
            neighborhood: userAddress.neighborhood || '',
            zip_code: userAddress.zip_code || '',
          },
        },
      );

      if (res.data.valid_address === true) {
        setRestaurantDeliveryTax(res.data.address.delivery_tax_price);
        history.push('/payments');
      } else {
        openModal();
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'token_not_sent':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'delivery_max_distance':
            setModalPhrase('Infelizmente não entregamos neste endereço informado.')
            openModalErrorApi()
          break;

          case 'google_api_status_not_ok':
            setModalPhrase('Infelizmente não entregamos neste endereço informado.')
            openModalErrorApi()
          break;

          case 'address_not_found':
            setModalPhrase('Infelizmente não entregamos neste endereço informado.')
            openModalErrorApi()
          break;


          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.')
            openModalErrorApi()
          break;

          default:
            openModalErrorApi()
        }
      }
    }
  }

  async function handleRegisterAddress(data) {

    try {

      if (formRef.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        street: Yup.string()
          .required('Nome obrigatório'),
        number: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('Número obrigatório'),
        city: Yup.string()
          .required('Cidade é obrigatória'),
        neighboorhood: Yup.string()
          .required('Bairro obrigatório'),

      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const res = await api.post('/client/delivery-addresses', {
        country: 'BR',
        state: selectedState,
        city: data.city,
        neighborhood: data.neighboorhood,
        street: data.street,
        number: data.number,
        complement: data.complement || '',
        reference: data.reference,
        zip_code: data.zip_code || '',
      });


      const response = await api.post(
        'client/restaurant-delivery-address/validate',
        {
          restaurant_id: restaurantId,
          delivery_address: {
            city: data.city || '',
            country: 'BR',
            state: selectedState || '',
            neighborhood: data.neighboorhood || '',
            zip_code: data.zip_code || '',
          },
        },
      );

      if (response.data.valid_address === true) {
        setRestaurantDeliveryTax(response.data.address.delivery_tax_price);

        setUserAddressId(res.data.id);

        setUserAddress({
          country: 'BR',
          state: selectedState,
          city: data.city,
          neighborhood: data.neighboorhood,
          street: data.street,
          number: data.number,
          complement: data.complement || '',
          reference: data.reference || '',
          zip_code: data.zip_code || '',
        });

        history.push('/payments');
      } else {
        openModal();
      }

    } catch (err) {

      if(err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        if (!err.response.ok){
          switch (err.response.data.errorType) {
            case 'invalid_token':
              userLogout();
              toggleModalErrorApiToken();
            break;

            case 'token_not_sent':
              userLogout();
              toggleModalErrorApiToken();
            break;

            case 'delivery_max_distance':
              setModalPhrase('Infelizmente não entregamos neste endereço informado.')
              openModalErrorApi()
            break;

            case 'google_api_status_not_ok':
              setModalPhrase('Infelizmente não entregamos neste endereço informado.')
              openModalErrorApi()
            break;

            case 'address_not_found':
              setModalPhrase('Infelizmente não entregamos neste endereço informado.')
              openModalErrorApi()
            break;


            case 'zip_code_not_found':
              setModalPhrase('Infelizmente não encontramos este CEP informado.')
              openModalErrorApi()
            break;

            default:
              setModalPhrase('Houve um erro ao cadastrar seu endereço, verifique seus dados digitados.')
              openModalErrorApi()
          }
          }
        }
    }
  }

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Infelizmente não entregamos no endereço informado.</p>
        </ModalTitle>

        <ModalFooter>
          <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Alterar meu endereço
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApi}
        onRequestClose={closeModalErrorApi}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalPhrase}</p>
        </ModalTitle>

        <ModalFooter>

          <ConfirmButton onClick={closeModalErrorApi}>
            Ok, irei verificar
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        onRequestClose={closeErrorModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Favor preencher os dados corretamente.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeErrorModal}>Ok, entendi</ConfirmButton>

        </ModalFooter>
      </Modal>


      <Modal
        isOpen={modalErrorApiToken}
        onRequestClose={toggleModalErrorApiToken}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Seu login expirou! Por segurança pedimos que faça login novamente, preenchendo seus dados. Iremos te direcionar para o login.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={handleNavigateLogin}>Ok, entendi</ConfirmButton>

        </ModalFooter>
      </Modal>
      {userAddress ? (
        <Content>
          <Header>
            <FiArrowLeft color="#fb4a20" onClick={handleNavigate} />
            <h1>Endereço Cadastrado</h1>
          </Header>

          <Text>
            <p>
              Você cadastrou esse endereço. Verifique se o restaurante atual
              entrega neste informado, ou altere o endereço.
            </p>
          </Text>

          <UserAddress>
            <p>
              {`${userAddress.street && `Logradouro: ${userAddress.street}`}, ${
                userAddress.number
              }`}
            </p>
            <p>
              {(userAddress.neighborhood &&
                `Bairro: ${userAddress.neighborhood}`) ||
                ''}
            </p>
            <p>
              {(userAddress.zip_code && `CEP: ${userAddress.zip_code}`) || ''}
            </p>
            <p>
              {(userAddress.complement &&
                `Complemento: ${userAddress.complement}`) ||
                ''}
            </p>
            <p>
              {(userAddress.reference &&
                `Referência: ${userAddress.reference}`) ||
                ''}
            </p>
            <p>{`${userAddress.city} - ${userAddress.state}`}</p>

          </UserAddress>

          <ButtonGroup>
            <ChangeAddress onClick={handleChangeAddress}>
              Alterar endereço
            </ChangeAddress>
          </ButtonGroup>

          <ButtonOrders onClick={handleValidateAddress}>
            Verificar e Prosseguir
          </ButtonOrders>
        </Content>
      ) : (
        <Content>
          <Header>
            <FiArrowLeft color="#fb4a20" onClick={handleNavigate} />
            <h1>Endereço para entrega</h1>
          </Header>

          <Text>
            <p>
              Verifique abaixo se temos disponibilidade para delivery em seu
              endereço.
            </p>
          </Text>

            <AddressField>
            <Form ref={formRef} onSubmit={handleRegisterAddress}>
              <InputField disableCep={disableCep}>

                  <Input
                    type="text"
                    placeholder="Seu CEP ..."
                    name="zip_code"
                    autoComplete="off"
                  />
              </InputField>

              <DesableCepButton type="button" onClick={handleDisableCep}>{disableCep ? 'Quero informar meu CEP' : 'Não sei meu CEP'  }</DesableCepButton>

              <StreetInputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                    value={selectedStreet}
                    onChange={e => {
                      setSelectedStreet(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputField>

                <InputField>

                  <Input
                    type="text"
                    placeholder="Número ..."
                    name="number"
                    autoComplete="off"
                 />
                </InputField>
              </StreetInputField>

              <InputField>
                <Input
                  type="text"
                  placeholder="Bairro ..."
                  name="neighboorhood"
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  placeholder="Cidade ..."
                  name="city"
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  name="complement"
                  placeholder="Complemento ..."
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  name="reference"
                  placeholder="Referência ..."
                />
              </InputField>

              <ButtonOrders type="submit">
                Cadastrar endereço
              </ButtonOrders>
            </Form>
          </AddressField>

        </Content>
      )}
    </Container>
  );
};

export default SearchAddressByDistance;

import React, { useState, useEffect } from 'react';
import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import { useHistory, Link, useLocation } from 'react-router-dom';

import ScrollToTop from '../../components/ScrollToTop';
import Modal from 'react-modal'

import formatValue from '../../utils/formatValue';

import {
  FiMinusCircle,
  FiPlusCircle,
  FiMessageSquare,
  FiArrowLeft,
  FiAlertCircle,
} from 'react-icons/fi';

import {
  Container,
  Content,
  ImageProduct,
  DescriptionProduct,
  AddItemsProduct,
  HeaderAction,
  EditAction,
  EditActionButtons,
  ComplementsHeaderProduct,
  ComplementItem,
  ComplementItemDescription,
  ComplementItemDescriptionLimit,
  ComplementItemAmount,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  InformationText,
} from './styles';

const Product = props => {
  const history = useHistory();
  const { addToCart } = useCart();
  const { restaurantId, restaurantIsOpen } = useAuth();

  const location = useLocation();
  const { item: product } = location.state;

  const [observation, setObservation] = useState('');
  const [amount, setAmount] = useState(1);
  const [complementsCategory, setComplementsCategory] = useState([]);
  const [mandatoryComplements, setMandatoryComplements] = useState([]);
  const [modal, setModal] = useState(false)
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  useEffect(() => {
    const mandatory = product.complement_categories.filter(
      item => item.optional === false,
    );

    setMandatoryComplements(mandatory);
  }, [product.complement_categories]);

  function handleAddObservation(e) {
    setObservation(e.target.value);
  }

  function increment() {
    setAmount(amount + 1);
  }

  function decrement() {
    setAmount(amount - 1);
  }

  function toggleModal(){
    setModal(!modal);
  }

  function handleAddComplements(
    id,
    name,
    price,
    limit,
    categoryLimit,
    categoryId,
    categoryAdditional,
  ) {
    //adicionar os complementos das categorias obrigatorias no state
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === id);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount += 1;
      }
    }

    //Adicionando aos complementos
    const newCategoryExist = [...complementsCategory];
    const categoryExist = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExist >= 0) {
      const categoryTotalAmount = complementsCategory[
        categoryExist
      ].complements.reduce((acum, curr) => acum + curr.amount, 0);

      const complementExist = newCategoryExist[
        categoryExist
      ].complements.findIndex(complement => complement.id === id);

      if (complementExist >= 0) {
        if (categoryTotalAmount < categoryLimit) {
          if (
            newCategoryExist[categoryExist].complements[complementExist]
              .amount < limit
          ) {
            newCategoryExist[categoryExist].complements[
              complementExist
            ].amount += 1;
          } else {
            alert(
              'Você só pode adicionar outro complemento ao seu lanche, pois o limite deste complemento foi alcançado.',
            );
          }
        } else {
          alert('Limite de catgoria');
        }
      } else {
        newCategoryExist[categoryExist].complements.push({
          id,
          name,
          price,
          amount: 1,
        });
      }

      setComplementsCategory(newCategoryExist);
    } else {
      setComplementsCategory([
        ...complementsCategory,
        {
          id: categoryId,
          additional: categoryAdditional,
          complements: [{ id, name, price, amount: 1 }],
        },
      ]);
    }
  }

  function handleRemoveComplements(categoryId, complementId) {
    //remover os complementos das categorias obrigatorias no state
    const categoryFind = mandatoryComplements.findIndex(
      category => category.id === categoryId,
    );
    if (categoryFind >= 0) {
      const complementFind = mandatoryComplements[
        categoryFind
      ].complements.findIndex(item => item.id === complementId);

      if (complementFind >= 0) {
        mandatoryComplements[categoryFind].complements[
          complementFind
        ].checkAmount -= 1;
      }
    }

    //Remover complemento das categorias
    const newCategoryExist = [...complementsCategory];
    const categoryExistId = newCategoryExist.findIndex(
      category => category.id === categoryId,
    );

    if (categoryExistId >= 0) {
      const complementExist = newCategoryExist[
        categoryExistId
      ].complements.findIndex(complement => complement.id === complementId);

      if (
        newCategoryExist[categoryExistId].complements[complementExist].amount >
        0
      ) {
        newCategoryExist[categoryExistId].complements[
          complementExist
        ].amount -= 1;

        setComplementsCategory(newCategoryExist);
      } else {
        const filteredComplements = newCategoryExist[
          categoryExistId
        ].complements.filter(complement => complement.id !== complementId);

        setComplementsCategory(filteredComplements);
      }
    }
  }

  const totalComplementsPrice = complementsCategory
    .filter(category => category.additional === true)
    .map(complement =>
      complement.complements
        .map(item => item)
        .reduce((acum, curr) => acum + curr.price * curr.amount, 0),
    )
    .reduce((acum, curr) => acum + curr, 0);

  function totalCategoryComplementsAmount(categoryId) {
    const total = complementsCategory
      .filter(category => category.id === categoryId)
      .map(item =>
        item.complements.reduce((acum, curr) => acum + curr.amount, 0),
      );

    return total;
  }

  function addToCarts() {
    if (restaurantIsOpen.toString() === 'true') {
      if (mandatoryComplements.length > 0) {
        const checkedMandatoryComplements = check.reduce(
          (acum, curr) => acum && curr > 0,
        );

        if (checkedMandatoryComplements) {
          addToCart({
            product,
            amount,
            observation,
            complementsCategory,
          });

          history.push(`/menu`);
        } else {
          alert('Favor adicionar todos os complementos obrigatórios');
        }
      } else {
        addToCart({
          product,
          amount,
          observation,
          complementsCategory,
        });

        history.push(`/menu`);
      }
    } else {
      toggleModal();
    }
  }

  const check = [];

  for (let cat of mandatoryComplements) {
    const category = cat.complements.reduce(
      (acum, curr) => acum + curr.checkAmount,
      0,
    );

    check.push(category);
  }

  return (
    <Container>
      <Modal
          isOpen={modal}
          onRequestClose={toggleModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>Restaurante fechado no momento, volte mais tarde, por favor.</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton
              onClick={toggleModal}
            >
              Confirmar
            </ConfirmButton>

          </ModalFooter>
        </Modal>
      <Content>
        <ScrollToTop />
        <HeaderAction>

            <FiArrowLeft onClick={()=>{window.history.back()}} size={18} color="#fb4a20"/>

        </HeaderAction>

        {product.image && (
          <ImageProduct>
            <img src={product.image.url} alt={product.description} />
          </ImageProduct>
        )}

        <DescriptionProduct>
          <h1>{product.name}</h1>
          <p>{product.description}</p>
        </DescriptionProduct>

        <AddItemsProduct>
          <div>
            <EditAction>
              Quantidade
              <EditActionButtons>
                <button onClick={decrement}>
                  {amount > 1 && <FiMinusCircle size={18} color="#fb4a20" />}
                </button>
                <p>{amount}</p>
                <button onClick={increment}>
                  <FiPlusCircle size={18} color="#fb4a20" />
                </button>
              </EditActionButtons>
            </EditAction>

            {product.complement_categories.map(complement => (
              <React.Fragment key={complement.id}>
                <ComplementsHeaderProduct>
                  <div>
                    <p>{complement.question}</p>
                    <span>
                      Escolha até {complement.limit}
                      {complement.limit > 1 ? ' itens' : ' item'} no total.
                    </span>
                  </div>

                  {complement.optional !== true && <p>Obrigatório</p>}
                </ComplementsHeaderProduct>
                <ComplementItem>
                  {complement.complements.map(item => (
                    <li key={item.id}>
                      <ComplementItemDescription>
                        <p>{item.name}</p>
                        {(Number(item.price) > 0) &
                        (complement.additional === true) ? (
                          <span>{formatValue(item.price)}</span>
                        ) : null}
                        {complement.limit > 1 && (
                          <ComplementItemDescriptionLimit>
                            Até {item.limit} {item.limit > 1 ? 'itens' : 'item'}
                          </ComplementItemDescriptionLimit>
                        )}
                      </ComplementItemDescription>

                      <ComplementItemAmount>
                        {complementsCategory
                          .filter(category => category.id === complement.id)
                          .map(cat =>
                            cat.complements
                              .filter(complement => complement.id === item.id)
                              .map(filtered => {
                                if (filtered.amount >= 1) {
                                  return (
                                    <React.Fragment key={filtered.id}>
                                      <FiMinusCircle
                                        size={18}
                                        color="#fb4a20"
                                        type="button"
                                        onClick={() => {
                                          handleRemoveComplements(
                                            complement.id,
                                            item.id,
                                          );
                                        }}
                                      />
                                      <p>{filtered.amount}</p>
                                    </React.Fragment>
                                  );
                                } else {
                                  return '';
                                }
                              }),
                          )}

                        {totalCategoryComplementsAmount(complement.id) <
                          complement.limit && (
                          <FiPlusCircle
                            size={18}
                            color="#fb4a20"
                            type="button"
                            onClick={() => {
                              handleAddComplements(
                                item.id,
                                item.name,
                                item.price,
                                item.limit,
                                complement.limit,
                                complement.id,
                                complement.additional,
                              );
                            }}
                          />
                        )}
                      </ComplementItemAmount>
                    </li>
                  ))}
                </ComplementItem>
              </React.Fragment>
            ))}

            <InformationText>
              <p>
                <FiMessageSquare color="#fb4a20" /> Quer fazer alguma
                observação?
              </p>
              <input
                placeholder="Ex: adicionar gelo e limão ... "
                value={observation}
                onChange={e => {
                  handleAddObservation(e);
                }}
                maxLength="40"
              />
            </InformationText>
          </div>
        </AddItemsProduct>
        <footer>
          {product.sold_off ? (
            <button className="btn-primary">
              Produto Esgotado
              <span>
                <FiAlertCircle size={22} />
              </span>
            </button>
          ) : (
            <button className="btn-primary" onClick={addToCarts}>
              Adicionar ao carrinho
              <span>
                {formatValue(
                  amount *
                    (Number(totalComplementsPrice) + Number(product.price)),
                )}
              </span>
            </button>
          )}
        </footer>
      </Content>
    </Container>
  );
};

export default Product;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import ScrollContainer from 'react-indiana-drag-scroll';
import Modal from 'react-modal';

import {
  FiChevronDown,
  FiArrowLeft,
  FiClock,
  FiMessageCircle,
  FiShoppingBag,
} from 'react-icons/fi';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import {useMenu} from '../../context/menu';

import takeatLogo from '../../assets/images/takeatLogoRosa.svg';

import api from '../../services/api';
import formatValue from '../../utils/formatValue';

import fotoPadrao from '../../assets/images/fotoPadrao.png';
import logo_icon from '../../assets/images/logo_icon.png';
import {
  ProductsEmpty,
  ButtonFoodcourt,
  ButtonWhatsapp,
  Container,
  Content,
  MenuContent,
  MenuItem,
  RestaurantStatus,
  MenuItemHeader,
  MenuItemBody,
  MenuItemDescription,
  MenuItemPrice,
  MenuItemPromotion,
  Badge,
  HeaderAction,
  AddToCart,
  Footer,
  ButtonGoToCart,
  CountBadge,
  ButtonAction,
  SoldOff,
  LoadingMenu,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
} from './styles';

const Menu = props => {
  const { cart } = useCart();
  const {menu} = useMenu()
  const {
    tableNumber,
    restaurantFantasyName,
    shoppingName,
    userToken,
    restaurantWhatsapp,
    restaurantIsOpen,
    setUserDeliveryType,
    setRestaurantCouponCode,
    userLogout,

  } = useAuth();
  const [menuReady, ] = useState(menu);
  const [isLoading, setIsLoading] = useState(true);
  const [modalPhrase, setModalPhrase] = useState('');
  // const { restaurant_id } = props.match.params;
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const verifyToken = async () => {
    try {
      await api.get('/client/verify/session')
      console.log('Token Ok')
    } catch (err){
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case'invalid_token':
           console.log(err.message)
            userLogout();
        }
     }
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  // useEffect(()=>{
  //   verifyToken();
  // }, [])

  useEffect(() => {
    setRestaurantCouponCode('');
    setUserDeliveryType('');
  }, [setRestaurantCouponCode, setUserDeliveryType]);

  // useEffect(() => {
  //   try {
  //     async function getMenu() {
  //       const response = await api.get(
  //         `public/restaurants/menu/${restaurant_id}`,
  //       );
  //       setMenu(response.data);
  //       setIsLoading(false);
  //     }

  //     getMenu();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [restaurant_id, history, tableNumber]);

  function handleGoToCart() {
    if (cart.length < 1) {
      openModal('Seu carrinho ainda está vazio.');
    } else {
      history.push('/cart');
    }
  }

  function handleOpenWhatsapp() {
    if (!!restaurantWhatsapp) {
      window.open(`https://wa.me/${restaurantWhatsapp}`);
    } else {
      openModal(
        'Este Restaurante ainda não possui o serviço de Chat, favor comparecer ao balcão.',
      );
    }
  }

  function handleGoToOrders() {
    if (!userToken) {
      openModal('Você ainda não fez pedidos.');
    } else {
      history.push('/orders');
    }
  }

  // return isLoading ? (
  //   <LoadingMenu>
  //     <img src={takeatLogo} alt="Logo Takeat" />
  //     <h1>Carregando Cardápio ...</h1>
  //   </LoadingMenu>
  // ) : (
    return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalPhrase}</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>
      <Content>
        {/* {restaurantIsOpen.toString() === 'false' ? (
          <RestaurantStatus>
            <p>Restaurante fechado</p>
          </RestaurantStatus>
        ) : (
          ''
        )} */}

        <HeaderAction>
          <header>
            <FiArrowLeft
              color="#333"
              onClick={() => {
                history.push(`/qrexpress/${shoppingName}`);
              }}
            />
            <h1>{restaurantFantasyName}</h1>
          </header>
          <nav>
            <ScrollContainer className="scroll-container">
            {menuReady && menuReady.length > 0 && menuReady.map(category => (
                <React.Fragment key={category.id}>
                  {category.products.length > 0 && (
                    <li>
                      <Link
                        className="styled-links"
                        to={category.name}
                        spy={true}
                        smooth={true}
                        duration={900}
                        offset={-100}
                      >
                        {category.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ScrollContainer>
          </nav>
        </HeaderAction>
        {menuReady && menuReady.length > 0 ? (
          <MenuContent>
            {menuReady.map(category => (
              <React.Fragment key={category.id}>
                {category.products.length >= 1 && (
                  <MenuItem>
                    <MenuItemHeader name={category.name}>
                      <strong>{category.name}</strong>
                      <div>
                        {category.preparation_time > 0 && (
                          <span>
                            <FiClock />
                            <p>{category.preparation_time} min.</p>
                          </span>
                        )}
                        <FiChevronDown size={10} color="#333" />
                      </div>
                    </MenuItemHeader>
                    {category.products.map(item => (
                      <MenuItemBody key={item.id}>
                        {item.price_promotion ? (
                          <AddToCart
                            disable={item.sold_off.toString()}
                            to={{
                              pathname: '/product',
                              state: {
                                item: { ...item, price: item.price_promotion },
                              },
                            }}
                          >
                            <div>
                              {item.image && (
                                <img
                                  src={item.image.url || fotoPadrao}
                                  alt={item.nome || 'foto'}
                                  style={{ width: 68 }}
                                />
                              )}

                              <MenuItemDescription
                                disable={item.sold_off.toString()}
                              >
                                <strong>{item.name}</strong>
                                <p>{item.description}</p>
                              </MenuItemDescription>
                            </div>

                            <MenuItemPrice>
                              {item.price_promotion ? (
                                <>
                                  <MenuItemPromotion>
                                    {formatValue(item.price)}
                                  </MenuItemPromotion>
                                  <p>{formatValue(item.price_promotion)}</p>
                                </>
                              ) : (
                                <p>{formatValue(item.price)}</p>
                              )}

                              {item.promotion && (
                                <Badge>{item.promotion}</Badge>
                              )}
                              {item.sold_off && <SoldOff>Esgotado</SoldOff>}
                            </MenuItemPrice>
                          </AddToCart>
                        ) : (
                          <AddToCart
                            disable={item.sold_off.toString()}
                            to={{
                              pathname: '/product',
                              state: { item },
                            }}
                          >
                            <div>
                              {item.image && (
                                <img
                                  src={item.image.url || fotoPadrao}
                                  alt={item.nome || 'foto'}
                                  style={{ width: 68 }}
                                />
                              )}

                              <MenuItemDescription
                                disable={item.sold_off.toString()}
                              >
                                <strong>{item.name}</strong>
                                <p>{item.description}</p>
                              </MenuItemDescription>
                            </div>

                            <MenuItemPrice>
                              {item.price_promotion ? (
                                <>
                                  <MenuItemPromotion>
                                    {formatValue(item.price)}
                                  </MenuItemPromotion>
                                  <p>{formatValue(item.price_promotion)}</p>
                                </>
                              ) : (
                                <p>{formatValue(item.price)}</p>
                              )}

                              {item.promotion && (
                                <Badge>{item.promotion}</Badge>
                              )}
                              {item.sold_off && <SoldOff>Esgotado</SoldOff>}
                            </MenuItemPrice>
                          </AddToCart>
                        )}
                      </MenuItemBody>
                    ))}
                  </MenuItem>
                )}
              </React.Fragment>
            ))}
          </MenuContent>
        ) : (
          <ProductsEmpty>
            <h1>
              Este restaurante <br /> está com o cardápio vazio.
            </h1>
          </ProductsEmpty>
        )}

        <Footer>
          <div>
            <ButtonFoodcourt onClick={handleGoToOrders}>
              <FiShoppingBag size={20} color="#fb4a20" />
              Meus Pedidos
            </ButtonFoodcourt>
            <ButtonAction>
              <ButtonGoToCart onClick={handleGoToCart}>
                <img src={logo_icon} alt="logo da takeat" />
                {!!cart.length > 0 && <CountBadge>{cart.length}</CountBadge>}
              </ButtonGoToCart>
              <span>Pedir</span>
            </ButtonAction>
            <ButtonWhatsapp onClick={handleOpenWhatsapp}>
              <FiMessageCircle size={20} color="#fb4a20" />
              Fale Consoco
            </ButtonWhatsapp>
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Menu;

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4';
import { FiArrowLeft } from 'react-icons/fi';

import { useCart } from '../../context/cart';
import { useOrder } from '../../context/order';
import { useAuth } from '../../context/auth';

import api from '../../services/api';

import formatValue from '../../utils/formatValue';

import {
  Container,
  Header,
  Content,
  Footer,
  Orders,
  OrdersDetails,
  OrderPrice,
  OrderComplement,
  OrderProduct,
  PaymentMethod,
  PaymentMethodArea,
  CouponArea,
  InputCoupon,
  CouponSelect,
  Coupon,
  CouponStatus,
  OrderObservations,
  OrderImage,
  Order,
  CouponDiscount,
  TotalPrice,
  RouterDomLink,
  CouponInfo,
  AddressArea,
  PaymentMethodType,
  ConfirmAddress,
} from './styles';

import LoadingPayment from '../LoadingPayment';

const ConfirmOrder = () => {
  const { ordersTotalValue } = useOrder();
  const { addCartToOrder, cart } = useCart();
  const couponInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderValue, setOrderValue] = useState(ordersTotalValue);
  const [coupons, setCoupons] = useState([]);
  const [couponDiscount, setCouponDiscount] = useState('');
  const [couponStatus, setCouponsStatus] = useState('');
  const [activatePhrase, setActivatePhrase] = useState('');
  const {
    restaurantHasSmsService,
    userChosePaySms,
    clientPaySms,
    userPaymentMethod,
    userDeliveryType,
    userAddress,
    restaurantId,
    setRestaurantCouponCode,
    restaurantDeliveryTax,
    restaurantDeliveryTime,
    userChange,
    isDeliveryByDistance
  } = useAuth();

  const history = useHistory();

  function handleGoPayments() {
    history.push('/payments');
  }

  function getCouponCode(e) {
    couponInputRef.current.value = e.target.id;
  }

  function handleChangeAddress() {
    if(isDeliveryByDistance){
      history.push('/searchaddressbydistance');
    } else {
      history.push('/searchaddress');
    }

  }

  const handleMakeOrder = useCallback(
    async payment_method => {
      setIsLoading(true);

      await addCartToOrder(payment_method);

      setIsLoading(false);
    },

    [addCartToOrder],
  );

  function handleGoToInputCard() {
    history.push('/inputcard');
  }

  async function validateCoupon() {
    try {
      const discount = await api.post(
        'public/orders/discount-coupon/validate',
        {
          order: [...cart],
          restaurant_id: restaurantId,
          coupon_code: couponInputRef.current.value.toUpperCase(),
          will_receive_sms: userChosePaySms,
        },
      );

      setOrderValue(discount.data.newOrderBasket.total_price);

      setCouponDiscount(discount.data.discount);
      setCouponsStatus('activated');

      setRestaurantCouponCode(couponInputRef.current.value.toUpperCase());

      setActivatePhrase('Cupom ativado com sucesso!');
    } catch (err) {
      if (!err.response.ok) {
        if (err.response.data.errorType === 'coupon_wrong_restaurant') {
          setActivatePhrase('Este cupom não pertence a este restaurante.');
        }

        if (err.response.data.errorType === 'coupon_amount_ended') {
          setActivatePhrase('Este cupom não está mais disponível.');
        }

        if (err.response.data.errorType === 'coupon_not_active') {
          setActivatePhrase('Este cupom não está ativo');
        }

        if (err.response.data.errorType === 'coupon_not_found') {
          setActivatePhrase('Cupom não encontrado');
        }

        if (err.response.data.errorType === 'coupon_date_expired') {
          setActivatePhrase('Este cupom expirou');
        }

        if (err.response.data.errorType === 'coupon_minimum_price') {
          setActivatePhrase(
            'O valor da compra está abaixo do valor mínimo pro cupom',
          );
        }
      }

      setCouponsStatus('error');
      setRestaurantCouponCode('');
    }
  }

  useEffect(() => {
    async function getCoupons() {
      try {
        const couponsRestaurant = await api.get(
          `/public/orders/discount-coupons/restaurants/${restaurantId}`,
        );

        setCoupons(couponsRestaurant.data);
      } catch (err) {
        console.log(err);
      }
    }

    getCoupons();
  }, [restaurantId]);

  return isLoading ? (
    <LoadingPayment title="Redirecionando para o pagamento" />
  ) : (
    <Container>
      <Content>
        <Header>
          <RouterDomLink to="/payments">
            <FiArrowLeft />
          </RouterDomLink>
          <h1>Confirmar compra</h1>
        </Header>

        <Orders>
          <h3>Pedidos</h3>

          {cart.map(item => (
            <Order key={uuid()}>
              {item.image && (
                <OrderImage>
                  <img src={item.image.url} alt={item.name} />
                </OrderImage>
              )}

              <OrdersDetails>
                <OrderProduct>
                  <p>{`${item.amount}x - `}</p>
                  <p>{item.name}</p>
                </OrderProduct>
                <OrderComplement>
                  {item.complement_categories.map(category =>
                    category.complements.map(items => (
                      <p key={uuid()}>{items.name}</p>
                    )),
                  )}
                </OrderComplement>
                <OrderObservations>{item.observation}</OrderObservations>
                <OrderPrice>
                  {formatValue(
                    item.complement_categories.reduce(
                      (categoryAccumulator, category) => {
                        const totalComplements = category.complements.reduce(
                          (complementAccumulator, complement) => {
                            return (
                              complementAccumulator +
                              complement.price * complement.amount
                            );
                          },
                          0,
                        );
                        return (
                          Number(categoryAccumulator) + Number(totalComplements)
                        );
                      },
                      0,
                    ) + Number(item.price),
                  )}
                </OrderPrice>
              </OrdersDetails>
            </Order>
          ))}
        </Orders>

        <PaymentMethodArea>
          <h3>Método de pagamento</h3>
          <PaymentMethod>
            {userPaymentMethod.name.toString() === 'picpay' && (
              <PaymentMethodType>
                <p>Pagar online</p>
                <p>{userPaymentMethod.name}</p>
              </PaymentMethodType>
            )}
            {userPaymentMethod.type.toString() === 'online' &&
              userPaymentMethod.name.toString() === 'credito' && (
                <PaymentMethodType>
                  <p>Pagar online</p>
                  <p>{userPaymentMethod.name}</p>
                </PaymentMethodType>
              )}
            {userPaymentMethod.type.toString() === 'delivery' && (
              <PaymentMethodType>
                <p>Pagar na entrega</p>
                <p>{userPaymentMethod.name}</p>
                {userPaymentMethod.keyword.toString() === 'dinheiro' && (
                  <p>
                    {userChange > 0
                      ? `Troco para R$ ${userChange}`
                      : 'Não quero troco'}
                  </p>
                )}
              </PaymentMethodType>
            )}
            {userPaymentMethod.type.toString() === 'withdrawal' && (
              <p>Pagar na retirada</p>
            )}
            <button type="button" onClick={handleGoPayments}>
              Alterar
            </button>
          </PaymentMethod>
        </PaymentMethodArea>

        {userDeliveryType.toString() === 'delivery' && (
          <AddressArea>
            <h3>Endereço de entrega</h3>
            <ConfirmAddress>
              <div>
                <p>{`${userAddress.street}, ${userAddress.number} - ${userAddress.neighborhood}`}</p>
                <p>{userAddress.complement || ''}</p>
                <p>{userAddress.reference || ''}</p>
                <p>{`${userAddress.city}, ${userAddress.state}`}</p>
                <p>
                  {restaurantDeliveryTax &&
                    `Taxa de entrega: ${formatValue(restaurantDeliveryTax)}`}
                </p>

                  {restaurantDeliveryTime > 0 &&
                    <p>Tempo de entrega: Aprox. {restaurantDeliveryTime} min.</p>}

              </div>
              <button type="button" onClick={handleChangeAddress}>
                Alterar
              </button>
            </ConfirmAddress>
          </AddressArea>
        )}

        <CouponArea>
          <h3>Adicionar cupom</h3>

          <InputCoupon>
            <input
              type="text"
              placeholder="Cole ou digite o código aqui"
              ref={couponInputRef}
            />
            <button type="button" onClick={validateCoupon}>
              Validar
            </button>
          </InputCoupon>

          <CouponStatus status={couponStatus}>{activatePhrase}</CouponStatus>

          {coupons.length > 0 && (
            <p>... ou selecione abaixo 1 cupom disponível</p>
          )}

          <CouponSelect>
            {coupons.map(item => (
              <Coupon
                id={item.code}
                onClick={e => {
                  getCouponCode(e);
                }}
                key={uuid()}
              >
                {item.name}
                {item.minimum_price > 0 && (
                  <CouponInfo
                    id={item.code}
                    onClick={e => {
                      getCouponCode(e);
                    }}
                  >
                    {`Min. de produtos: ${formatValue(item.minimum_price)}`}
                  </CouponInfo>
                )}

                {item.maximum_discount && (
                  <CouponInfo
                    id={item.code}
                    onClick={e => {
                      getCouponCode(e);
                    }}
                  >
                    {`Desconto max.: ${formatValue(item.maximum_discount)}`}
                  </CouponInfo>
                )}
              </Coupon>
            ))}
          </CouponSelect>
        </CouponArea>
      </Content>

      <Footer>
        {restaurantHasSmsService.toString() === 'true' &&
        clientPaySms.toString() === 'true' &&
        userChosePaySms.toString() === 'true' ? (
          <div>
            <small>Valor Total + SMS</small>
            {couponDiscount && (
              <CouponDiscount>{formatValue(couponDiscount)}</CouponDiscount>
            )}
            {userDeliveryType === 'delivery' ? (
              <TotalPrice>
                {formatValue(
                  Number(orderValue) + Number(restaurantDeliveryTax) + 0.1,
                )}
              </TotalPrice>
            ) : (
              <TotalPrice>{formatValue(Number(orderValue) + 0.1)}</TotalPrice>
            )}
          </div>
        ) : (
          <div>
            <small>Valor Total</small>
            {couponDiscount && (
              <CouponDiscount>{formatValue(couponDiscount)}</CouponDiscount>
            )}
            {userDeliveryType === 'delivery' ? (
              <TotalPrice>
                {formatValue(
                  Number(orderValue) + Number(restaurantDeliveryTax),
                )}
              </TotalPrice>
            ) : (
              <TotalPrice>{formatValue(Number(orderValue))}</TotalPrice>
            )}
          </div>
        )}

        {userPaymentMethod.name.toString() === 'picpay' && (
          <button
            type="submit"
            onClick={() => {
              handleMakeOrder('picpay');
            }}
          >
            Pagar
          </button>
        )}

        {userPaymentMethod.type.toString() === 'delivery' && (
          <button
            type="submit"
            onClick={() => {
              handleMakeOrder('delivery');
            }}
          >
            Pagar
          </button>
        )}

        {userPaymentMethod.type.toString() === 'withdrawal' && (
          <button
            type="submit"
            onClick={() => {
              handleMakeOrder('withdrawal');
            }}
          >
            Pagar
          </button>
        )}

        {userPaymentMethod.name.toString() === 'credito' && (
          <button type="submit" onClick={handleGoToInputCard}>
            Pagar
          </button>
        )}
      </Footer>
    </Container>
  );
};

export default ConfirmOrder;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';

import api from '../../services/api';

import { useAuth } from '../../context/auth';
import { useCart } from '../../context/cart';

import { FiMapPin } from 'react-icons/fi';
import { FaUtensils } from 'react-icons/fa';

import {
  Container,
  Content,
  Message,
  Restaurants,
  Restaurant,
  ButtonOrders,
  ProfileFood,
  LocalFood,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  RestaurantClosed,
  RestaurantMinimumPrice
} from './styles';

const Places = () => {
  const { closeCart } = useCart();

  const {
    setRestaurantId,
    setRestaurantFantasyName,
    setShopping,
    setSmsOptions,
    setRestaurantWhatsapp,
    setRestaurantAcceptsPicpay,
    setRestaurantAcceptsPaytime,
    setRestaurantIsOpen,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryActive,
    setRestaurantDeliveryPayments,
    setRestaurantDeliveryTime,
    setRestaurantWithdrawalTime,
    setRestaurantUsername,
    setRestaurantProductPromotion,
    setRestaurantDeliveryMinimumPrice,
    setPicpayReferenceId,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    userLogout,
    setUserAddress,
  } = useAuth();
  const history = useHistory();
  const { shopping_name } = useParams();

  const [restaurantsPriority, setRestaurantsPriority] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const verifyToken = async () => {
    try {
      await api.get('/client/verify/session')
      console.log('Token Ok')
    } catch (err){
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case'invalid_token':
           console.log(err.message)
            userLogout();
            setUserAddress('')
        }
     }
    }
  }

  function handleNavigate(
    id,
    fantasy_name,
    client_pay_sms,
    is_sms_optional,
    has_sms_service,
    whatsapp,
    has_picpay_service,
    has_paytime_service,
    restaurant_opened,
    withdrawal_allowed,
    has_delivery_service,
    has_delivery_active,
    restaurant_delivery_payments,
    restaurant_delivery_time,
    restaurant_witdrawal_time,
    restaurant_product_promotion = null,
    restaurant_username,
    restaurant_delivery_minimum_price,
    is_delivery_by_distance,
    restaurant_address_state
  ) {
    const restaurantWhatsapp = whatsapp
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace(' ', '')
      .replace('+', '')
      .replace('-', '');

      setRestaurantId(id);
      setRestaurantFantasyName(fantasy_name);
      setRestaurantWhatsapp(restaurantWhatsapp);
      setSmsOptions(client_pay_sms, is_sms_optional, has_sms_service);
      setRestaurantAcceptsPicpay(has_picpay_service);
      setRestaurantAcceptsPaytime(has_paytime_service);
      setRestaurantIsOpen(restaurant_opened);
      setRestaurantAcceptsWithdrawal(withdrawal_allowed);
      setRestaurantProductPromotion(restaurant_product_promotion);
      setRestaurantAcceptsDelivery(has_delivery_service);
      setRestaurantDeliveryActive(has_delivery_active);
      setRestaurantDeliveryPayments(restaurant_delivery_payments);
      setRestaurantUsername(restaurant_username);
      setRestaurantDeliveryTime(restaurant_delivery_time);
      setRestaurantWithdrawalTime(restaurant_witdrawal_time);
      setRestaurantDeliveryMinimumPrice(restaurant_delivery_minimum_price);
      setIsDeliveryByDistance(is_delivery_by_distance);
      setRestaurantAddress(restaurant_address_state);
      closeCart();
      history.push(`/menu`);
  }

  // async function getRestaurantData(){
  //   const response = await api.get(`/public/restaurant/${shopping_name}`)

  //   const {id}=response.data;

  //   setRestaurantId(id)

  // }

  // useEffect(()=>{
  //   try{
  //     getRestaurantData()
  //   } catch(error){
  //     console.log(error.message)
  //   }
  // }, [])

  useEffect(()=>{
    setPicpayReferenceId('')
  }, [setPicpayReferenceId])

  useEffect(()=>{
    verifyToken()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/public/qrexpress/${shopping_name}`);
      const {
        id,
        username,
        priority_restaurants,
      } = response.data;

      setShopping(id, username);

      setRestaurantsPriority(priority_restaurants);
      setRestaurantId(priority_restaurants[0].id)
      console.log(priority_restaurants)
    }
    fetchData();
  }, [setShopping, shopping_name]);

  return (
    <Container>

      <Content>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>Restaurante fechado, favor voltar mais tarde ...</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>
        <Restaurants>
          {restaurantsPriority.map(restaurant => (
            <Restaurant
              key={restaurant.id}
              onClick={() => {
                handleNavigate(
                  restaurant.id,
                  restaurant.fantasy_name,
                  restaurant.client_pay_sms,
                  restaurant.is_sms_service_optional,
                  restaurant.has_sms_service,
                  restaurant.whatsapp || '',
                  restaurant.has_picpay_service,
                  restaurant.has_paytime_service,
                  restaurant.opened,
                  restaurant.withdrawal_allowed || false,
                  restaurant.is_delivery_allowed || false,
                  restaurant.is_delivery_active || false,
                  restaurant.payment_methods || null,
                  restaurant.time_to_delivery || null,
                  restaurant.time_to_withdrawal || null,
                  restaurant.product_promotion,
                  restaurant.username,
                  restaurant.minimum_delivery_price,
                  restaurant.is_delivery_by_distance || '',
                  restaurant.address?.state || null
                );
              }}
            >
              <Message>
                <h1>Seja Bem-Vindo(a)!</h1>
              </Message>
              {/* {setRestaurantId(restaurant.id)} */}
              <ProfileFood>
                {!!restaurant.avatar && (
                  <img
                    src={restaurant.avatar.url}
                    alt={restaurant.fantasy_name}
                  />
                )}

                <p>{restaurant.fantasy_name}</p>
              </ProfileFood>
              {!!restaurant.location && (
                <LocalFood>
                  <FiMapPin size={10} />
                  <p>{restaurant.location}</p>
                </LocalFood>
              )}
              {restaurant.opened.toString()==='false' && (
                <RestaurantClosed>
                  Restaurante Fechado
                </RestaurantClosed>
              )}
              {restaurant.minimum_delivery_price > 0 && (
                <RestaurantMinimumPrice>{`Pedido minimo no delivery: R$ ${restaurant.minimum_delivery_price}`}</RestaurantMinimumPrice>
              )}
              <ButtonOrders
                onClick={() => {
                  handleNavigate(
                    restaurant.id,
                    restaurant.fantasy_name,
                    restaurant.client_pay_sms,
                    restaurant.is_sms_optional,
                    restaurant.has_sms_service,
                    restaurant.whatsapp || '',
                    restaurant.has_picpay_service,
                    restaurant.has_paytime_service,
                    restaurant.opened,
                    restaurant.withdrawal_allowed || false,
                    restaurant.is_delivery_allowed || false,
                    restaurant.is_delivery_active || false,
                    restaurant.payment_methods || null,
                    restaurant.time_to_delivery || null,
                    restaurant.time_to_withdrawal || null,
                    restaurant.product_promotion,
                    restaurant.username,
                    restaurant.minimum_delivery_price,
                    restaurant.is_delivery_by_distance || '',
                    restaurant.address?.state || null
                  );
                }}
              >
                <FaUtensils />
                <span>Acessar cardápio</span>
              </ButtonOrders>
            </Restaurant>
          ))}
        </Restaurants>
      </Content>
    </Container>
  );
};

export default Places;

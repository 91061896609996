import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../context/auth';

import { FaStar } from 'react-icons/fa';

import {
  Order,
  OrderHeader,
  OrderBody,
  OrderFooter,
  OrderStatus,
  OrdersInfo,
  OrdersRating,
} from './styles';

const Finished = ({ order }) => {
  const { setOrderBasketId } = useAuth();
  const history = useHistory();

  function handleGoRating(id) {
    history.push('/rating');
    setOrderBasketId(id);
  }

  return (
    <Order key={order.id}>
      <OrderHeader>
        <strong>{order.restaurant.fantasy_name}</strong>
        <p>Senha {order.attendance_password.password_number}</p>
      </OrderHeader>
      <OrderBody>
        {order.orders.map(item => (
          <p key={item.id}>{`${item.amount}x ${item.product.name}`}</p>
        ))}
      </OrderBody>
      <OrdersInfo>
        <OrderStatus status={order.order_status}>
          <p> Pedido entregue </p>
        </OrderStatus>
        <OrdersRating
          onClick={() => {
            handleGoRating(order.id);
          }}
        >
          <p>Avaliar</p>
          <div>
            <FaStar color="#ffa500" />
            <FaStar color="#ffa500" />
            <FaStar color="#ffa500" />
            <FaStar color="#ffa500" />
            <FaStar color="#ffa500" />
          </div>
        </OrdersRating>
      </OrdersInfo>

      <OrderFooter to={{ pathname: '/myordersdetails', state: { order } }}>
        <p>Ver detalhes</p>
      </OrderFooter>
    </Order>
  );
};

export default Finished;

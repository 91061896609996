import React, { useState } from 'react';
import api from '../../../../services/api';

import Modal from 'react-modal';

import { FiDollarSign, FiX } from 'react-icons/fi';

import {
  Order,
  OrderHeader,
  OrderBody,
  OrderFooter,
  OrderStatus,
  ButtonsAction,
  RemakePayment,
  CancelOrder,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  CancelButton,
} from './styles';

const PaymentPending = ({ order, fetchData }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  async function handleCancelItem(order_id) {
    await api.put('/client/orders/cancel', { order_uuid: order_id });

    fetchData();

    closeModal();
  }

  return (
    <Order>
      <OrderHeader>
        <strong>{order.restaurant.fantasy_name}</strong>
        <p>Senha {order.attendance_password.password_number}</p>
      </OrderHeader>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Deseja realmente cancelar seu pedido?</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton
            onClick={() => {
              handleCancelItem(order.order_uuid);
            }}
          >
            Confirmar
          </ConfirmButton>
          <CancelButton onClick={closeModal}>Cancelar</CancelButton>
        </ModalFooter>
      </Modal>
      <OrderBody>
        {order.orders.map(item => (
          <p key={item.id}>{`${item.amount}x ${item.product.name}`}</p>
        ))}
      </OrderBody>

      <OrderStatus status={order.order_status}>Pagamento pendente</OrderStatus>

      <ButtonsAction>
        <RemakePayment href={order.payment_url} target="_blank">
          <FiDollarSign />
          <p> Refazer pagamento </p>
        </RemakePayment>

        <CancelOrder onClick={openModal}>
          <FiX />
          <p>Cancelar pedido</p>
        </CancelOrder>
      </ButtonsAction>
      <OrderFooter to={{ pathname: '/myordersdetails', state: { order } }}>
        <p>Ver detalhes</p>
      </OrderFooter>
    </Order>
  );
};

export default PaymentPending;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft, FiClock } from 'react-icons/fi';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Header,
  ButtonDeliveryType,
  ButtonGroup,
  Title,
  DeliveryTime,
} from './styles';

import motoBoy from '../../assets/images/motoboy.jpg';
import withdrawalltype from '../../assets/images/withdrawalltype.jpg';

const DeliveryType = () => {
  const history = useHistory();
  const {
    setUserDeliveryType,
    restaurantWithdrawalTime,
    restaurantDeliveryTime,
    isDeliveryByDistance
  } = useAuth();

  function handleConfirmDelivery() {
    setUserDeliveryType('delivery');

    if(isDeliveryByDistance.toString()==='true'){
      history.push('/searchaddressbydistance');
    } else {
      history.push('/searchaddress');
    }


  }

  function handleConfirmWithdrawal() {
    setUserDeliveryType('withdrawal');
    history.push('/payments');
  }

  return (
    <Container>
      <Content>
        <Header>
          <FiArrowLeft
            onClick={() => {
              history.push('/cart');
            }}
            color="rgba(251, 74, 32, 1)"
          />
          <h1>Método de Retirada</h1>
        </Header>

        <Title>Escolha abaixo o método de retirada.</Title>

        <ButtonGroup>
          <ButtonDeliveryType onClick={handleConfirmDelivery}>
            <img
              src={motoBoy}
              alt="imagem de um motoboy"
            />
            <div>
              <p>
                Delivery
              </p>
              <DeliveryTime>

                {restaurantDeliveryTime > 0 && (
                  <>
                    <FiClock/>
                     <p>
                     {`Aprox. ${restaurantDeliveryTime} minutos`}
                   </p>
                   </>
                )}

              </DeliveryTime>
            </div>
          </ButtonDeliveryType>
          <ButtonDeliveryType onClick={handleConfirmWithdrawal} >
            <img
              src={withdrawalltype}
              alt="imagem de retirada no balcao"
            />
            <div>
              <p>
                Retirar no balcão
              </p>
              <DeliveryTime>

                {restaurantWithdrawalTime > 0 && (
                  <>
                  <FiClock />
                     <p>
                     {`Aprox. ${restaurantWithdrawalTime} minutos`}
                   </p>
                   </>
                )}

              </DeliveryTime>
            </div>
          </ButtonDeliveryType>
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default DeliveryType;

import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import {uuid} from 'uuidv4';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import api from '../../services/api';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Header,
  ButtonOrders,
  SelectField,
  SelectStyled,
  SelectGroup,
  Text,
  AddressField,
  StreetInputField,
  AcceptsPhrase,
  InputField,
  UserAddress,
  ButtonGroup,
  ChangeAddress,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  CancelButton,
  // ValidAddressTitle,
} from './styles';

const SearchAddress = () => {
  const {
    restaurantId,
    restaurantUsername,
    setUserAddressId,
    setUserAddress,
    userAddress,
    setRestaurantDeliveryTax,
    userLogout
  } = useAuth();
  const history = useHistory();

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalErrorApi, setModalErrorApi] = useState(false);
  const [modalErrorApiToken, setModalErrorApiToken] = useState(false);

  Modal.setAppElement('#root');

  const [, setCount] = useState();

  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedNeihgboorhood, setSelectedNeihgboorhood] = useState('');
  const [selectedStreet, setSelectedStreet] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [selectedZipCode, setSelectedZipCode] = useState('');
  const [selectedComplement, setSelectedComplement] = useState('');
  const [selectedReference, setSelectedReference] = useState('');

  const [optionStates, setOptionStates] = useState([]);
  const [optionCities, setOptionCities] = useState([]);
  const [optionNeihgboorhood, setOptionNeihgboorhood] = useState([]);

  const [isZipCodeLimited, setIsZipCodeLimited] = useState('');
  const [acceptsAllNeihgboorhoods, setAcceptsAllNeihgboorhoods] = useState(
    false,
  );

  const maskOnlyNumbers = value => {
    return value.replace(/\D/g, '');
  };

  const getCitiesData = useCallback(async () => {
    try {
      const res = await api.get(
        `public/restaurants/${restaurantId}/delivery-addresses`,
      );

      const dataCountries = res.data.countries;

      dataCountries
        .map(country => country.states)
        .map(state =>
          state.map(item => {
            if (state.length === 1) {
              setSelectedState(item.name);
              setOptionStates('');
            } else if (state.length > 1) {
              setOptionStates(prevState => [
                ...prevState,
                {
                  value: item.name,
                  label: item.name,
                },
              ]);
            }
          }),
        );
    } catch (e) {
      console.log(e.message)
    }
  }, [restaurantId])

  useEffect(() => {
    getCitiesData();
  }, [getCitiesData]);

  useEffect(() => {
    async function fetchDatas() {
      const res = await api.get(
        `public/restaurants/${restaurantId}/delivery-addresses`,
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map(item => item.states)
        .map(item =>
          item.filter(statesFound => statesFound.name === selectedState),
        );

      const parsedCities = state.map(item =>
        item
          .map(citiesFound => citiesFound.cities)
          .map(cityArray =>
            cityArray.map(cityArrayFound => {
              return {
                value: cityArrayFound.name,
                label: cityArrayFound.name,
              };
            }),
          ),
      );

      setOptionCities(parsedCities[0][0]);
    }

    fetchDatas();
  }, [selectedState, restaurantId]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get(
        `public/restaurants/${restaurantId}/delivery-addresses`,
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map(item => item.states)
        .map(item =>
          item.filter(statesFound => statesFound.name === selectedState),
        );

      const cityParsed = state.map(item =>
        item
          .map(it => it.cities)
          .map(cty => cty.filter(ct => ct.name === selectedCity)),
      );

      const neighborhoodsFound = [];

      cityParsed[0].map(ct =>
        ct.map(nb => {
          nb.neighborhoods.map(nbfound => neighborhoodsFound.push(nbfound));
          if (nb.neighborhoods.length > 0) {
            setAcceptsAllNeihgboorhoods(false);
          } else {
            setAcceptsAllNeihgboorhoods(true);
          }
        }),
      );

      // setDeliveryNeihgboorhood(neighborhoodsFound);

      const neighborhoodsParsed = neighborhoodsFound.map(nb => {
        return {
          value: nb.name,
          label: nb.name,
        };
      });

      if (neighborhoodsParsed.length > 0) {
        setOptionNeihgboorhood(neighborhoodsParsed);
      } else {
        setOptionNeihgboorhood('');
      }
    }
    fetchData();
  }, [selectedCity, selectedState, restaurantId]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get(`/public/restaurant/${restaurantUsername}`);

      const { delivery_addresses } = res.data;

      const neighboorhoodFind = delivery_addresses
        .map(add => add)
        .filter(ad => ad.neighborhood === selectedNeihgboorhood);

      if (neighboorhoodFind.length > 0) {
        setIsZipCodeLimited(neighboorhoodFind[0].is_zip_code_limited);
      }
    }

    fetchData();
  }, [selectedNeihgboorhood, restaurantUsername, setRestaurantDeliveryTax]);

   function handleNavigate() {
    history.push('/deliverytype');
  }

  function handleChangeAddress() {
    setUserAddress('');

    const aleatoryId = uuid()
    setCount(aleatoryId)
   }

  function closeModal() {
    setIsOpen(false);
  }

  function closeErrorModal() {
    setModalErrorIsOpen(false);
  }

  function closeModalErrorApi() {
    setModalErrorApi(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openErrorModal() {
    setModalErrorIsOpen(true);
  }

  function openModalErrorApi() {
    setModalErrorApi(true);
  }

  function toggleModalErrorApiToken(){
    setModalErrorApiToken(!modalErrorApiToken)
  }

  function closeModalWithChange() {
    setIsOpen(false);
    handleChangeAddress();
  }

  function handleNavigateLogin(){
    toggleModalErrorApiToken();
    history.push('/cart')
  }

  async function handleValidateAddress() {
    try {
      const res = await api.post(
        'client/restaurant-delivery-address/validate',
        {
          restaurant_id: restaurantId,
          delivery_address: {
            city: userAddress.city || '',
            country: userAddress.country || '',
            state: userAddress.state || '',
            neighborhood: userAddress.neighborhood || '',
            zip_code: userAddress.zip_code || '',
          },
        },
      );

      if (res.data.valid_address === true) {
        setRestaurantDeliveryTax(res.data.address.delivery_tax_price);
        history.push('/payments');
      } else {
        openModal();
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'token_not_sent':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'delivery_max_distance':
            openModal();
            break;

          case 'google_api_status_not_ok':
            openModal();
            break;

          case 'address_not_found':
            openModal();
            break;

          case 'zip_code_not_found':
            openModal();
            break;

          default:
            openModalErrorApi()
        }
      }
    }
  }

  async function handleRegisterAddress() {
    try {
      if (isZipCodeLimited === true) {
        if (
          !!selectedCity &&
          !!selectedState &&
          !!selectedNeihgboorhood &&
          !!selectedStreet &&
          !!selectedNumber &&
          !!selectedZipCode
        ) {
          const res = await api.post('/client/delivery-addresses', {
            country: 'BR',
            state: selectedState,
            city: selectedCity,
            neighborhood: selectedNeihgboorhood,
            street: selectedStreet,
            number: selectedNumber,
            complement: selectedComplement || '',
            reference: selectedReference,
            zip_code: selectedZipCode || '',
          });



          const response = await api.post(
            'client/restaurant-delivery-address/validate',
            {
              restaurant_id: restaurantId,
              delivery_address: {
                city: selectedCity || '',
                country: 'BR',
                state: selectedState || '',
                neighborhood: selectedNeihgboorhood || '',
                zip_code: selectedZipCode || '',
              },
            },
          );

          if (response.data.valid_address === true) {
            setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
            setUserAddressId(res.data.id);
            setUserAddress({
              country: 'BR',
              state: selectedState,
              city: selectedCity,
              neighborhood: selectedNeihgboorhood,
              street: selectedStreet,
              number: selectedNumber,
              complement: selectedComplement || '',
              reference: selectedReference || '',
              zip_code: selectedZipCode || '',
            });

            history.push('/payments');
          } else {
            openModal();
          }

        } else {
          openErrorModal()
        }
      } else if (
        !!selectedCity &&
        !!selectedState &&
        !!selectedNeihgboorhood &&
        !!selectedStreet &&
        !!selectedNumber
      ) {
        const res = await api.post('/client/delivery-addresses', {
          country: 'BR',
          state: selectedState,
          city: selectedCity,
          neighborhood: selectedNeihgboorhood,
          street: selectedStreet,
          number: selectedNumber,
          complement: selectedComplement || '',
          reference: selectedReference,
          zip_code: selectedZipCode || '',
        });

        const response = await api.post(
          'client/restaurant-delivery-address/validate',
          {
            restaurant_id: restaurantId,
            delivery_address: {
              city: selectedCity || '',
              country: 'BR',
              state: selectedState || '',
              neighborhood: selectedNeihgboorhood || '',
              zip_code: selectedZipCode || '',
            },
          },
        );

        if (response.data.valid_address === true) {
          setRestaurantDeliveryTax(response.data.address.delivery_tax_price);

          setUserAddressId(res.data.id);

          setUserAddress({
            country: 'BR',
            state: selectedState,
            city: selectedCity,
            neighborhood: selectedNeihgboorhood,
            street: selectedStreet,
            number: selectedNumber,
            complement: selectedComplement || '',
            reference: selectedReference || '',
            zip_code: selectedZipCode || '',
          });

          history.push('/payments');
        } else {
          openModal();
        }

      } else {
        openErrorModal()
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'token_not_sent':
            userLogout();
            toggleModalErrorApiToken();
          break;

          case 'delivery_max_distance':
            openModal();
            break;

          case 'google_api_status_not_ok':
            openModal();
            break;

          case 'address_not_found':
            openModal();
            break;

           case 'zip_code_not_found':
            openModal();
              break;

          default:
            openModalErrorApi()
        }
      }

    }
  }

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Infelizmente não entregamos no endereço informado.</p>
        </ModalTitle>

        <ModalFooter>
          <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Alterar meu endereço
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApi}
        onRequestClose={closeModalErrorApi}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Houve um erro ao cadastrar seu endereço, verifique seus dados digitados.</p>
        </ModalTitle>

        <ModalFooter>

          <ConfirmButton onClick={closeModalErrorApi}>
            Ok, irei verificar
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        onRequestClose={closeErrorModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Favor preencher os dados corretamente.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeErrorModal}>Ok, entendi</ConfirmButton>

        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApiToken}
        onRequestClose={toggleModalErrorApiToken}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Seu login expirou! Por segurança pedimos que faça login novamente, preenchendo seus dados. Iremos te direcionar para o login.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={handleNavigateLogin}>Ok, entendi</ConfirmButton>

        </ModalFooter>
      </Modal>
      {userAddress ? (
        <Content>
          <Header>
            <FiArrowLeft color="#fb4a20" onClick={handleNavigate} />
            <h1>Endereço Cadastrado</h1>
          </Header>

          <Text>
            <p>
              Você cadastrou esse endereço. Verifique se o restaurante atual
              entrega neste informado, ou altere o endereço.
            </p>
          </Text>

          <UserAddress>
            <p>
              {`${userAddress.street && `Logradouro: ${userAddress.street}`}, ${
                userAddress.number
              }`}
            </p>
            <p>
              {(userAddress.neighborhood &&
                `Bairro: ${userAddress.neighborhood}`) ||
                ''}
            </p>
            <p>
              {(userAddress.zip_code && `CEP: ${userAddress.zip_code}`) || ''}
            </p>
            <p>
              {(userAddress.complement &&
                `Complemento: ${userAddress.complement}`) ||
                ''}
            </p>
            <p>
              {(userAddress.reference &&
                `Referência: ${userAddress.reference}`) ||
                ''}
            </p>
            <p>{`${userAddress.city} - ${userAddress.state}`}</p>

          </UserAddress>

          <ButtonGroup>
            <ChangeAddress onClick={handleChangeAddress}>
              Alterar endereço
            </ChangeAddress>

          </ButtonGroup>


          <ButtonOrders onClick={handleValidateAddress}>
            Verificar e Prosseguir
          </ButtonOrders>
        </Content>
      ) : (
        <Content>
          <Header>
            <FiArrowLeft color="#fb4a20" onClick={handleNavigate} />
            <h1>Endereço para entrega</h1>
          </Header>

          <Text>
            <p>
              Verifique abaixo se temos disponibilidade para delivery em seu
              bairro.
            </p>
          </Text>

          <SelectGroup>
            {optionStates.length > 0 && (
              <SelectField>
                <p>Estado (UF)</p>
                <SelectStyled
                  name="state"
                  options={optionStates}
                  placeholder="Selecione seu estado ..."
                  onChange={e => {
                    setSelectedState(e.value);
                  }}
                  isSearchable
                  noOptionsMessage={() => 'nada encontrado ...'}
                />
              </SelectField>
            )}

            <SelectField>
              <p>Cidade</p>
              <SelectStyled
                name="cities"
                options={optionCities}
                placeholder="Selecione sua cidade ..."
                onChange={e => {
                  setSelectedCity(e.value);
                }}
                isSearchable
                noOptionsMessage={() => 'nada encontrado ...'}
              />
            </SelectField>

            {optionNeihgboorhood.length > 0 && (
              <SelectField>
                <p>Bairro</p>
                <SelectStyled
                  name="neihgboorhoods"
                  options={optionNeihgboorhood}
                  placeholder="Selecione seu bairro ..."
                  onChange={e => {
                    setSelectedNeihgboorhood(e.value);
                  }}
                  isSearchable
                  noOptionsMessage={() => 'nada encontrado ...'}
                />
              </SelectField>
            )}
          </SelectGroup>

          {acceptsAllNeihgboorhoods.toString() === 'true' && (
            <AcceptsPhrase>
              <p>{`Aceitamos delivery em todos os bairros em ${selectedCity}`}</p>
            </AcceptsPhrase>
          )}

          {!!selectedState && !!selectedCity && (
            <AddressField>
              <p>Agora nos diga onde você mora</p>

              <StreetInputField>
                <InputField>
                  <p>Rua</p>
                  <input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                    value={selectedStreet}
                    onChange={e => {
                      setSelectedStreet(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <p>Número</p>
                  <input
                    type="text"
                    placeholder="Num."
                    name="number"
                    id="number"
                    value={selectedNumber}
                    onChange={e => {
                      setSelectedNumber(maskOnlyNumbers(e.target.value));
                    }}
                    autoComplete="off"
                  />
                </InputField>
              </StreetInputField>
              {optionNeihgboorhood.length === 0 && (
                <InputField>
                  <p>Bairro</p>
                  <input
                    type="text"
                    placeholder="Bairro"
                    name="neighboorhoodInput"
                    value={selectedNeihgboorhood}
                    onChange={e => {
                      setSelectedNeihgboorhood(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputField>
              )}

              {isZipCodeLimited === true && (
                <InputField>
                  <p>CEP</p>
                  <input
                    type="text"
                    placeholder="Seu CEP"
                    name="cep"
                    value={selectedZipCode}
                    onChange={e => {
                      setSelectedZipCode(maskOnlyNumbers(e.target.value));
                    }}
                    autoComplete="off"
                  />
                </InputField>
              )}

              <InputField>
                <p>Complemento</p>
                <input
                  type="text"
                  name="complement"
                  placeholder="Ex: Casa 3, fundos"
                  value={selectedComplement}
                  onChange={e => {
                    setSelectedComplement(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <p>Referência</p>
                <input
                  type="text"
                  name="reference"
                  placeholder="Ex: Prox. ao mercado x"
                  value={selectedReference}
                  onChange={e => {
                    setSelectedReference(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>
            </AddressField>
          )}

          <ButtonOrders onClick={handleRegisterAddress}>
            Cadastrar endereço
          </ButtonOrders>
        </Content>
      )}
    </Container>
  );
};

export default SearchAddress;
